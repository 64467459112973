import { CSSProperties } from "react";
import surfImg from "../shared/assets/surf1.jpg";
import { tkeys } from "../translations";
import { useTranslation } from "react-i18next";

function LDG() {
  const { t } = useTranslation();


  return (
    <>
      <div id="ldg" className="my-20 mx-2 xl:mt-0 md:grid md:grid-cols-2">
        <div className="hidden md:flex justify-center items-center	"  >
          <img
            className="object-cover md:h-1/3  lg:h-1/3 lg:w-3/4 xl:h-2/4 xl:w-2/4  filter grayscale-25
                      bg-purple-500 shadow-2xl shadow-purple-500 hover:shadow-indigo-700
                        hover:grayscale-0 transition ease-in-out duration-500"
            src={surfImg}
            alt="surf img"
          />
        </div>
        <div className="hidden md:block p-8">
          <p className="text-left text-withe p-ldg text-2xl md:text-xl lg:text-2xl">
            {t(tkeys.sobreMiTexto1)}
          </p>
          <p className="text-left text-withe p-ldg text-2xl md:text-xl lg:text-2xl pt-10">
            {t(tkeys.sobreMiTexto2)}
          </p>
          <p className="text-left text-withe p-ldg text-2xl md:text-xl lg:text-2xl pt-10">
            {t(tkeys.sobreMiTexto3)}
          </p>
          <p className="text-left first-letter:text-withe p-ldg text-2xl md:text-xl lg:text-2xl pt-10">
            {t(tkeys.sobreMiTexto4)}
          </p>
        </div>

        {/* -------- mobile ---------- */}

        <div className="md:hidden p-8">
          <p className="text-left text-withe p-ldg text-xl">
            {t(tkeys.sobreMiTexto1)}
          </p>
          <p className="text-left text-withe p-ldg text-xl pt-10">
            {t(tkeys.sobreMiTexto2)}
          </p>
          <p className="text-left text-withe p-ldg text-xl pt-10">
            {t(tkeys.sobreMiTexto3)}
          </p>
          <p className="text-left first-letter:text-withe p-ldg text-xl pt-10">
            {t(tkeys.sobreMiTexto4)}
          </p>
        </div>
      </div >
      {/* <div className="iframe-rec my-10">
        <iframe src='https://widgets.sociablekit.com/linkedin-recommendations/iframe/166152'
          width='100%' height='100%'></iframe>
      </div> */}
    </>
  );
}

export default LDG;
