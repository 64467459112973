import React, { useEffect, useState } from "react";
import "./App.css";
import Contact from "./components/Contact";
import CV from "./components/CV";
import Experience from "./components/Experience";
import Footer from "./components/Footer";
import Home from "./components/Home";
import LDG from "./components/LDG";
import NavBar from "./components/NavBar";

function App({ hideLoader, showLoader }: any) {
  const [darkMode, setDarkMode] = useState(true);
  const [isLoading, setLoading] = useState(true);

  function someRequest() {
    //Simulates a request; makes a "promise" that'll run for 2.5 seconds
    return new Promise<void>((resolve) => setTimeout(() => resolve(), 2500));
  }

  useEffect(() => {
    someRequest().then(() => {
      const loaderElement = document.querySelector(".loader-container");
      if (loaderElement) {
        loaderElement.remove();
        setLoading(!isLoading);
      }
    });
  });

  function toggleDarkMode() {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  }

  const handleClickScroll = (positionId: string) => {
    const element = document.getElementById(positionId);
    const headerOffset = 50;
    if (element) {
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <div
      id="app"
      className={`App ${
        darkMode ? "bg-gray-800 text-gray-100" : "bg-gray-100 text-gray-800"
      } relative `}
    >
      <header className="App-header">
        <NavBar
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
          handleClickScroll={handleClickScroll}
        />
      </header>
      <div>
        <Home handleClickScroll={handleClickScroll} />
        <LDG />
        <Experience />
        <Contact />
        <CV />
        <Footer handleClickScroll={handleClickScroll} />
      </div>
    </div>
  );
}

export default App;
