import TabExperience from "./TabExperience";

function Experience() {
  return (
    <>
      <div id="xp" className="mt-20 lg:mt-0">
        <TabExperience />
      </div>
    </>
  );
}

export default Experience;
