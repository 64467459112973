/* eslint-disable react/jsx-no-target-blank */
import { useTranslation } from "react-i18next";
import contactImg from "../shared/assets/yo4.jpg";
import { tkeys } from "../translations";

function Contact() {
  const { t } = useTranslation();

  return (
    <>
      <div id="contact" className="mt-20 lg:mt-44 md:grid md:grid-cols-2">
        <div className="p-8">
          <p className="text-center text-withe p-ldg text-2xl">
            {t(tkeys.noLoDudes)}
          </p>
          <div className="box mt-10 inline-block">
            <a href="mailto:ldgcode@gmail.com" target="_blank" id="say_hi_link">
              <span className="flex" id="say_hi_link_span">
                {t(tkeys.dimeHola)}{" "}
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M309.752 35.514c-3.784.046-7.807.454-12.004 1.082-27.198 61.067-49.85 122.007-65.45 182.775-9.293-4.313-18.634-8.57-27.962-12.845-3.95-53.137 1.876-103.13 5.33-153.757-6.696-5.06-17.54-8.82-28.596-8.98-11.573-.166-22.304 3.33-28.537 9.513-5.44 70.22-5.258 147.354 1.133 217.475 21.926 29.733 45.877 59.903 52.305 103.64l-18.49 2.716c-4.24-28.837-17.583-51.34-33.238-73.51l-7.582-10.55c-5.01-6.862-10.134-13.79-15.185-20.945-21.397-28.51-44.094-51.49-62.155-59.22-9.81-4.196-17.273-4.385-24.632-.442-6.486 3.474-13.52 11.49-20.043 25.387 53.41 51.674 70.576 104.044 82.718 138.664 5.79 16.507 11.08 31.523 21.274 47.025 15.614 23.746 49.446 42.91 84.066 49.51 34.62 6.598 68.69.712 86.87-19.833 14.36-16.227 41.232-41.87 56.195-57.787 24.524-26.085 59.485-54.964 88.597-77.248 14.556-11.142 27.62-20.598 37.197-27.178 4.79-3.29 8.68-5.848 11.612-7.625.197-.12.34-.182.527-.294 1.31-9.873-.448-20.663-4.804-29.375-4.358-8.718-10.787-14.658-17.763-17.015-35.707 21.283-70.62 44.438-103.877 75.438-5.745-7.274-11.933-14.06-18.5-20.424 30.747-58.815 69.992-107.75 114.28-150.41-1.56-9.55-7.76-19.814-16.114-27.32-8.4-7.55-18.526-11.7-25.852-11.623-45.615 46.382-85.864 96.907-117.5 154.463-6.918-4.36-14.023-8.513-21.27-12.51 18.893-64.715 42.99-126.426 73.5-184.392-12.757-15.245-25.477-23.335-42.347-24.324-1.205-.07-2.44-.096-3.7-.08z"></path>
                </svg>
              </span>
              <i></i>
            </a>
          </div>
        </div>
        <div className="flex justify-center">

          <img
            className="object-cover w-2/5 h-2/3  md:w-3/5 md:h-2/3 lg:w-3/6 lg:h-2/3
              filter grayscale-25 bg-indigo-500 shadow-2xl shadow-indigo-500
               hover:shadow-purple-700 hover:grayscale-0 transition ease-in-out
                duration-500"

            src={contactImg}
            alt="home img"
          />
        </div>
      </div>
    </>
  );
}

export default Contact;
