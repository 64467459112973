/* eslint-disable react/jsx-no-target-blank */
import logo from "../shared/assets/Logo/LDG Logo/PNG/LDG-Logo-Sin-Fondo-c.png";
import { tkeys } from "../translations";
import { useTranslation } from "react-i18next";

function CV() {
  const { t } = useTranslation();

  return (
    <>
      <div id="cv" className="mt-20 lg:mt-0 md:grid md:grid-cols-2">
        <div className="flex justify-center">
          <img className="h-2/3 w-3/5 md:w-4/5 md:h-2/4 lg:w-4/6 lg:h-2/4 xl:w-3/6 xl:h-2/4" src={logo} alt="surf img" />
        </div>
        <div className="p-8 md:py-0 lg:py-8">
          <p className="text-center text-withe p-ldg text-xl md:text-2xl">
            {t(tkeys.cvText1)}
          </p>
          <div className="box mt-10 inline-block">
            <a href={t(tkeys.cvLink) || ""} target="_blank" id="resume_link">
              {" "}
              <span className="flex" id="resume_link_span"> {t(tkeys.cvDescargar)}</span>
            </a>
            <i></i>
          </div>
        </div>
      </div>
    </>
  );
}

export default CV;
