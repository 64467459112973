export const es = {
  inicio: "Inicio",
  LDG: "LDG",
  XP: "XP",
  devContacto: "Contacto",
  cv: "CV",

  holaMiNombreEs: "Hola, mi nombre es",
  nombreCompleto: "Lucas Damián Gómez",
  desarrolladorDeSoftware: "Desarrollador de software",
  desarrollandoConPasion: "Desarrollando con pasión.",

  sobreMi: "Sobre mi",
  // sobreMiTexto1: `¡Hola! mi nombre es Lucas, disfruto pasar mi tiempo libre en contacto con la naturaleza o conocer lugares nuevos con mi motocicleta pero por sobre todas las cosas soy muy afortunado ya que desde pequeño descubrí mi vocación, siempre fui un apasionado por la tecnología, pasaba miles de horas frente a la computadora, quedaba atrapado, mirando, intentando entender como sucedía la magia que tenía ante mí.`,
  // sobreMiTexto2: `Ya soy mayor, entiendo como funcionan muchas cosas y gracias a eso
  //   puedo vivir de lo que me apasiona, colaborando con diferentes
  //   equipos, aportando mis conocimientos y juntos alcanzando grandes
  //   objetivos.`,
  // sobreMiTexto3:
  //   "¿Sabes cuál es la mejor parte? la tecnología no se detiene, siempre hay trucos nuevos por aprender",
  // sobreMiTexto4: "¡La magia continua! :)",

  sobreMiTexto1:
    "¡Saludos! Soy Lucas, y me complace darte la bienvenida a mi sitio web personal. Me encanta estar en contacto con la naturaleza y recorrer nuevos lugares en mi motocicleta, pero mi verdadera pasión es la tecnología.",
  sobreMiTexto2:
    "Desde mi infancia, he sido un apasionado por el funcionamiento de los sistemas tecnológicos. Pasaba horas frente a la computadora tratando de entender cómo sucedía la magia que tenía ante mí. Con el tiempo, he adquirido conocimientos profundos y habilidades valiosas, lo que me ha permitido hacer de mi pasión mi carrera.",
  sobreMiTexto3:
    "En la actualidad, colaboro con diferentes equipos, aportando mi experiencia y conocimientos para alcanzar grandes objetivos. Siempre estoy buscando nuevas oportunidades para crecer y aprender en el mundo de la tecnología.",
  sobreMiTexto4:
    "Lo que más me motiva es que el campo tecnológico está en constante cambio y evolución, lo que me permite explorar nuevas técnicas y estrategias. Estoy emocionado por el futuro y por las posibilidades infinitas que se presentan cada día en mi camino. Si tienes alguna pregunta o necesitas asesoramiento en cuanto a tecnología, no dudes en contactarme. Estoy aquí para ayudarte.",

  experiencia: "Experiencia",
  appstractTitulo: "FULL STACK SOFTWARE DEVELOPER",
  appstract: "APPSTRACT",
  appstractFecha: "Ene 2019 - Nov 2023",
  appstractTexto1:
    "• Referente técnico para el nuevo equipo de desarrolladores.",
  appstractTexto2:
    "• Lideré y participé en el desarrollo de una aplicación core encargada de la gestión y seguimiento de todo flujo relacionado al rubro farmacéutico como prescripciones, auditoria médica, distribución, validaciones, liquidaciones, reportes, financiadores, beneficiarios, medicamentos, proveedores entre otras funcionalidades. Múltiples aplicaciones web en Angular 9+. API Rest en NodeJS, express, sequelize, mysql. Entorno en GCP (Google Cloud Platform).",
  appstractTexto3:
    "• Lideré y participé en el desarrollo de una aplicación para la gestión de pacientes, médicos, clientes, materiales, cotización, compras entre otras funcionalidades para una de las droguerías más grandes del país, Droguerías del Sud. (API Rest en .Net Core con una arquitectura basada en DDD y Web en Angular 9).",
  appstractTexto4:
    "• Participé en el desarrollo de un panel de control web (angular 8) y mobile (ionic 4) con una APIREST (NodeJs) y MongoDB, para la gestión y administración interna de documentos de órdenes de trabajo de la empresa AB InBev Argentina y AB InBev Brasil.",
  appstractTexto5:
    "• Coordiné y participé en el desarrollo de 2 webs y un panel de control, todo dinámico y 100% autoadministrable, para Grupo Sancor Seguros utilizando MVC .NET + Frameworks propios de la compañía basados en VueJs.",

  ldgcodeTitulo: "FUNDADOR",
  ldgcode: "LDGCode",
  ldgcodeFecha: "Ago 2017 - Nov 2020",
  ldgcodeTexto1:
    "Desarrollo web y software a medida, brindamos soluciones tecnológicas a los problemas que puedan surgir en pymes o emprendimientos.",

  gisTitulo: "SOFTWARE DEVELOPER",
  gis: "Get It Smart",
  gisFecha: "Feb 2017 – Ene 2019",
  gisTexto1:
    "• Desarrollé la API REST de una aplicación web para una agencia de turismo utilizando ASP.NET Web API.",
  gisTexto2:
    "• Estuve en un cliente (Lagash) desarrollando la aplicación web del B20 Argentina (G20 Argentina), utilizando ASP.NET MVC y jQuery.",
  gisTexto3:
    "• Participé en el mantenimiento de una aplicación web de gestión de cursos y capacitaciones del Gobierno Nacional, utilizando ASP.NET Web API y AngularJS.",
  gisTexto4:
    "• Desarrollé una API REST de contenido estático y una aplicación web de back office para un sistema de inversiones en bienes, utilizando ASP.NET Core y Angular.",
  gisTexto5:
    "• Coordiné y participé en el desarrollo de una API REST principal, otra de contenido estático y una aplicación web para un sistema de organización de eventos y servicios de catering empresarial, utilizando ASP.NET Core y Angular.",

  contacto: "Contacto",
  noLoDudes: "¿Quieres contactarme?. No lo dudes, simplemente...",
  dimeHola: "Dime Hola",

  curriculum: "CURRICULUM",
  cvText1:
    "Puedes descargar mi CV ahora, pero vuelve pronto, seguramente encontraras nuevos skills allí.",
  cvDescargar: "Descargar",

  cvLink:
  "https://drive.google.com/file/d/1cd2vuexd9UWJhl_pKt7UP-do270-QWfy/view?usp=sharing",
};
