/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../shared/assets/Logo/LDG Logo/PNG/LDG-Logo-Sin-Fondo-c.png";
import { tkeys } from "../translations";
import "flag-icons/css/flag-icons.css";

export default function NavBar({
  darkMode,
  toggleDarkMode,
  handleClickScroll,
}: any) {
  const [navbar, setNavbar] = useState(false);

  const { t, i18n } = useTranslation();
  const language = React.useRef(i18n.language);

  const handleChangeLanguage = (newLanguage: "es" | "en" | "pt") => {
    language.current = newLanguage;
    i18n.changeLanguage(language.current);
  };

  return (
    <>
      <nav
        className={`w-full shadow ${
          darkMode ? "bg-gray-800" : "bg-gray-100 "
        }`}
      >
        <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-between py-3 md:py-5 md:block">
              <a href="/" id="ldgcode_logo_link">
                <img src={logo} alt="logo" className="max-w-xs px-10 lg:px-0" id="ldgcode_logo_link_img" />
              </a>
              <div className="md:hidden">
                <button
                  className="pb-5 md:p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                  onClick={() => setNavbar(!navbar)}
                  id="burguer_link_button"
                >
                  {navbar ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-purple-700"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-purple-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            <div
              className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${
                navbar ? "block" : "hidden"
              }`}
            >
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                <li
                  key={"inicio"}
                  className="text-purple-700	 hover:text-indigo-400"
                >
                  <a
                    className="cursor-pointer"
                    onClick={() => handleClickScroll("home")}
                    id="nav_home_link"
                  >
                    <h1>{t(tkeys.inicio)}</h1>
                  </a>
                </li>
                <li
                  key={"ldg"}
                  className="text-purple-700 hover:text-indigo-400"
                >
                  <a
                    className="cursor-pointer"
                    onClick={() => handleClickScroll("ldg")}
                    id="nav_ldg_link"
                  >
                    {t(tkeys.LDG)}
                  </a>
                </li>
                <li
                  key={"xp"}
                  className="text-purple-700 hover:text-indigo-400"
                >
                  <a
                    className="cursor-pointer"
                    onClick={() => handleClickScroll("xp")}
                    id="nav_xp_link"
                  >
                    {t(tkeys.XP)}
                  </a>
                </li>
                <li
                  key={"contact"}
                  className="text-purple-700 hover:text-indigo-400"
                >
                  <a
                    className="cursor-pointer"
                    onClick={() => handleClickScroll("contact")}
                    id="nav_contact_link"
                  >
                    {t(tkeys.contacto)}
                  </a>
                </li>
                <li
                  key={"cv"}
                  className="text-purple-700 hover:text-indigo-400"
                >
                  <a
                    className="cursor-pointer"
                    onClick={() => handleClickScroll("cv")}
                    id="nav_cv_link"
                  >
                    {t(tkeys.cv)}
                  </a>
                </li>
                <li key={"mode"}>
                  <div className="flex justify-center">
                    <label className="inline-block pr-[0.35rem] opacity-50 hover:pointer-events-none">
                      {darkMode ? "Dark" : "Ligth"}
                    </label>
                    <input
                      key="switch"
                      className="mt-[0.3rem] mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-purple-600 dark:bg-neutral-600 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 dark:after:bg-neutral-400 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary dark:checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary dark:checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault01"
                      onChange={() => toggleDarkMode()}
                      checked={darkMode}
                    />
                  </div>
                </li>
                <li key={"language"}>
                  <ul className="flex justify-center">
                    <li key={"es"}>
                      <a onClick={() => handleChangeLanguage("es")} id="language_es_link">
                        <span className="fi fi-ar cursor-pointer" id="language_es_link_span"></span>
                      </a>
                    </li>
                    <li key={"en"}>
                      <a
                        style={{ marginLeft: 20 }}
                        onClick={() => handleChangeLanguage("en")}
                        id="language_en_link"
                      >
                        <span className="fi fi-us cursor-pointer" id="language_en_link_span"></span>
                      </a>
                    </li>
                    <li key={"pt"}>
                      <a
                        style={{ marginLeft: 20 }}
                        onClick={() => handleChangeLanguage("pt")}
                        id="language_pt_link"
                      >
                        <span className="fi fi-br cursor-pointer" id="language_pt_link_span"></span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
