export const en = {
  inicio: "Home",
  LDG: "LDG",
  XP: "XP",
  navContacto: "Contact",
  cv: "Resume",

  holaMiNombreEs: "Hello, my name is",
  nombreCompleto: "Lucas Damián Gómez",
  desarrolladorDeSoftware: "Software Developer",
  desarrollandoConPasion: "Developing with passion.",

  sobreMi: "About me",
  // sobreMiTexto1:
  //   "Hello, my name is Lucas, I like to spend my free time in contact with nature or getting to know new places with my motorcycle, but above all I am very lucky because since I was a child I discovered my vocation, I was always passionate about technology, I spent thousands of hours in front of the computer, I was trapped, watching, trying to understand how the magic happened in front of me.",
  // sobreMiTexto2:
  //   "I'm an adult now, I understand how many things work and thanks to that I can make a living from what I am passionate about, collaborating with different teams, contributing my knowledge and together reaching great goals.",
  // sobreMiTexto3:
  //   "You know what's the best? Technology never stops, there are always new tricks to learn.",
  // sobreMiTexto4: "The magic continues! :)",

  sobreMiTexto1:
    "Greetings! I'm Lucas, and I'm pleased to welcome you to my personal website. I love being in touch with nature and exploring new places on my motorcycle, but my true passion is technology.",
  sobreMiTexto2:
    "Since my childhood, I've been passionate about the functioning of technological systems. I spent hours in front of the computer trying to understand the magic that lay before me. Over time, I've acquired deep knowledge and valuable skills, allowing me to turn my passion into a career.",
  sobreMiTexto3:
    "Currently, I collaborate with different teams, contributing my expertise and knowledge to achieve great objectives. I'm always looking for new opportunities to grow and learn in the world of technology.",
  sobreMiTexto4:
    "What motivates me the most is that the technology field is constantly changing and evolving, allowing me to explore new techniques and strategies. I'm excited about the future and the infinite possibilities that present themselves on my path every day. If you have any questions or need technology advice, don't hesitate to contact me. I'm here to help.",

  experiencia: "Experience",
  appstractTitulo: "FULL STACK SOFTWARE DEVELOPER",
  appstract: "APPSTRACT",
  appstractFecha: "Jan 2019 - Nov 2023",
  appstractTexto1: "• Technical reference for the new team of developers.",
  appstractTexto2:
    "• I led and participated in the development of a core application responsible for the management and monitoring of all flows related to the pharmaceutical industry such as prescriptions, medical audit, distribution, validations, settlements, reports, funders, beneficiaries, drugs, suppliers among other functionalities. Multiple web applications in Angular 9+. API Rest in NodeJS, express, sequelize, mysql. Environment in GCP (Google Cloud Platform)",
  appstractTexto3:
    "• I led and participated in the development of an application for the management of patients, doctors, customers, materials, quotation, purchases among other functionalities for one of the largest drugstores in the country, Droguerías del Sud. (API Rest in .Net Core with an architecture based on DDD and Web in Angular 9).",
  appstractTexto4:
    "• I participated in the development of a web control panel (angular 8) and mobile (ionic 4) with an APIREST (NodeJs) and MongoDB, for the management and internal administration of work order documents of the company AB InBev Argentina and AB InBev Brazil.",
  appstractTexto5:
    "• Coordinated and participated in the development of 2 websites and a control panel, all dynamic and 100% self-managed, for Grupo Sancor Seguros using the company's own MVC .NET + Frameworks based on VueJs.",

  ldgcodeTitulo: "Founder",
  ldgcode: "LDGCode",
  ldgcodeFecha: "Aug 2017 - Nov 2020",
  ldgcodeTexto1:
    "We develop web pages and custom software, we provide technological solutions to problems that may arise in SMEs or companies.",

  gisTitulo: "SOFTWARE DEVELOPER",
  gis: "Get It Smart",
  gisFecha: "Feb 2017 – Jan 2019",
  gisTexto1:
    "• I developed the REST API of a web application for a tourism agency using ASP.NET Web API",
  gisTexto2:
    "• I was at a client (Lagash) developing the web application of B20 Argentina, using ASP.NET MVC and jQuery",
  gisTexto3:
    "• I participated in the maintenance of a web application for the management of courses and training of the National Government, using ASP.NET Web API and AngularJS",
  gisTexto4:
    "• I developed a REST API with static content and a back office web application for a system of investments in real estates, using ASP.NET Core and Angular",
  gisTexto5:
    "• Coordinated and participated in the development of a main REST API, another of static content and a web application for an event organization system and business catering services, using ASP.NET Core and Angular",

  contacto: "Contact",
  noLoDudes: "Would you like to contact me? Do not hesitate, just...",
  dimeHola: "say Hello",

  curriculum: "RESUME",
  cvText1:
    "You can download my RESUME now, but come back soon, you will surely find new skills there.",
  cvDescargar: "Download",

  cvLink:
    "https://drive.google.com/file/d/1Hie7hNCNDJrdCDSOr2BZrxFPEXvtnEOI/view?usp=sharing",
};
