/* eslint-disable react/jsx-no-target-blank */
import { FaLinkedin, FaInstagram, FaArrowCircleUp } from "react-icons/fa";

function Footer({ handleClickScroll }: any) {
  return (
    <>
      <div id="footer" className="md:grid md:grid-cols-3 md:my-10 ">
        <div className="justify-center my-5 hidden md:flex md:visible md:mx-5">
          Copyright © 2017 - 2023 LDGCode®. All rights reserved.
        </div>
        <div className="flex justify-center my-5">
          <a href="https://ar.linkedin.com/in/lucasdgomez" target={"_blank"} id="linkedin_link">
            <FaLinkedin size={30} className="mr-2 cursor-pointer" id="linkedin_link_logo" />
          </a>
          <a href="https://www.instagram.com/ldgcode" target={"_blank"} id="instagram_link">
            <FaInstagram size={30} className="mr-2 cursor-pointer" id="instagram_link_logo" />
          </a>
        </div>
        <div className="flex justify-center my-5 md:mx-5">
          <p className="md:invisible">
            Copyright © 2017 - 2023 LDGCode®. All rights reserved.
          </p>
          <FaArrowCircleUp
            size={30}
            className="hidden md:flex mr-2 cursor-pointer"
            onClick={() => handleClickScroll("app")}
            id="circle_up_link_logo"
          />
        </div>
      </div>
    </>
  );
}

export default Footer;
