import { useTranslation } from "react-i18next";
import homeImg from "../shared/assets/profile.jpg";
import { tkeys } from "../translations";

function Home({ handleClickScroll }: any) {
  const { t } = useTranslation();

  return (
    <>
      <div id="home" className="mt-5 md:mt-20 md:grid md:grid-cols-2">
        <div className="p-8">
          <h1 className="home-name">{t(tkeys.nombreCompleto)}</h1>
          <h2 className="home-title mt-10">
            {t(tkeys.desarrolladorDeSoftware)}
          </h2>
          <p className="home-intro mt-10">{t(tkeys.desarrollandoConPasion)}</p>
          {/* <p className="text-purple-700 sm:text-red-700 md:text-blue-700
   lg:text-green-700 xl:text-pink-400">
          responsive test 
          </p> */}
          <div
            className="box mt-10 inline-block sm:inline-block md:hidden lg:inline-block xl:inline-block 2xl:inline-block"
            onClick={() => handleClickScroll("ldg")}
            id="about_me_link_button"
          >
            <span> {t(tkeys.sobreMi)}</span>
            <i></i>
          </div>
        </div>

        <div className="flex justify-center mt-10">
          <img
            className="object-cover h-2/3 w-3/5 md:h-5/6 md:w-3/5 filter grayscale-25
            bg-indigo-500 shadow-2xl shadow-indigo-500 hover:shadow-purple-700
              hover:grayscale-0 transition ease-in-out duration-500"
            src={homeImg}
            alt="home img"
          />
        </div>
      </div>
    </>
  );
}

export default Home;
