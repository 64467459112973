import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { tkeys } from "../translations";

export default function Example() {
  const [currentTab, setCurrentTab] = useState("Appstract");
  const { t } = useTranslation();

  const data = [
    {
      label: "APPSTRACT",
      value: "Appstract",
      position: "appstractTitulo",
      date: "appstractFecha",
      items: [
        "appstractTexto1",
        "appstractTexto2",
        "appstractTexto3",
        "appstractTexto4",
        "appstractTexto5",
      ],
    },
    {
      label: "LDGCODE",
      value: "LdgCode",
      position: "ldgcodeTitulo",
      date: "ldgcodeFecha",
      items: ["ldgcodeTexto1"],
    },
    {
      label: "GET IT SMART",
      value: "GetItSmart",
      position: "gisTitulo",
      date: "gisFecha",
      items: ["gisTexto1", "gisTexto2", "gisTexto3", "gisTexto4", "gisTexto5"],
    },
  ];

  return (
    <Tabs id="custom-animation" value={currentTab}>
      <TabsHeader className="text-xl md:text-2xl cursor-pointer text-withe font-bold px-5">
        {data.map(({ label, value }) => (
          <Tab id={value} key={value} value={value} onClick={() => setCurrentTab(value)}>
            <p
              id={value}
              className={
                currentTab === value
                  ? "text-purple-600 underline underline-offset-4"
                  : "text-withe"
              }
            >
              {label}
            </p>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody
        className="mt-10"
        animate={{
          initial: { y: 250 },
          mount: { y: 0 },
          unmount: { y: 250 },
        }}
      >
        {data.map(({ value, position, date, items }) => (
          <TabPanel
            key={value}
            value={value}
            className="text-left px-5 md:px-10 lg:px-52 text-withe"
          >
            <h2 className="text-xl md:text-2xl my-10">{t(tkeys[position])}</h2>
            <h2 className="text-lg md:text-xl my-5">{t(tkeys[date])}</h2>
            {items.map((item, index) => {
              return (
                <p key={index} className="p-ldg text-lg md:text-xl pt-5">
                  {t(tkeys[item])}
                  <br></br>
                </p>
              );
            })}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}
