export const pt = {
  inicio: "Início",
  LDG: "LDG",
  XP: "XP",
  devContacto: "Contacto",
  cv: "CV",

  holaMiNombreEs: "Olá, meu nome é",
  nombreCompleto: "Lucas Damián Gómez",
  desarrolladorDeSoftware: "Desenvolvedor de software",
  desarrollandoConPasion: "Desenvolvendo com paixão.",

  sobreMi: "Sobre mim",
  // sobreMiTexto1: `Olá, meu nome é Lucas, gosto de passar meu tempo livre em contato com a natureza ou conhecer novos lugares com minha motocicleta mas acima de tudo tenho muita sorte porque desde criança descobri minha vocação, sempre fui apaixonado pela tecnologia, passei milhares de horas em frente ao computador, fiquei preso, observando, tentando entender como a magia que tinha diante de mim estava acontecendo.`,
  // sobreMiTexto2: `Estou mais velho agora, entendo quantas coisas funcionam e graças a isso posso ganhar a vida com o que sou apaixonado, colaborando com diferentes equipes, contribuindo com meu conhecimento e alcançando juntos grandes objetivos.`,
  // sobreMiTexto3:
  //   "Você sabe qual é a melhor parte, a tecnologia não fica parada, há sempre novos truques para aprender.",
  // sobreMiTexto4: "!La magia continúa¡ :)",

  sobreMiTexto1:
    "Olá! Eu sou o Lucas, e é um prazer dar-lhe as boas-vindas ao meu site pessoal. Eu adoro estar em contato com a natureza e explorar novos lugares na minha motocicleta, mas minha verdadeira paixão é a tecnologia.",
  sobreMiTexto2:
    "Desde a minha infância, sou apaixonado pelo funcionamento dos sistemas tecnológicos. Passava horas em frente ao computador tentando entender a mágica que tinha diante de mim. Com o tempo, adquiri conhecimentos profundos e habilidades valiosas, o que me permitiu transformar minha paixão em minha carreira.",
  sobreMiTexto3:
    "Atualmente, colaboro com diferentes equipes, contribuindo com minha experiência e conhecimento para alcançar grandes objetivos. Estou sempre procurando novas oportunidades para crescer e aprender no mundo da tecnologia.",
  sobreMiTexto4:
    "O que mais me motiva é que o campo tecnológico está em constante mudança e evolução, o que me permite explorar novas técnicas e estratégias. Estou animado com o futuro e as infinitas possibilidades que se apresentam em meu caminho todos os dias. Se você tiver alguma dúvida ou precisar de conselhos sobre tecnologia, não hesite em entrar em contato comigo. Estou aqui para ajudar.",

  experiencia: "Experiencia",
  appstractTitulo: "FULL STACK SOFTWARE DEVELOPER",
  appstract: "APPSTRACT",
  appstractFecha: "Ene 2019 - Nov 2023",
  appstractTexto1:
    "• Referência técnica para a nova equipe de desenvolvedores.",
  appstractTexto2:
    "• Liderei e participei do desenvolvimento de uma aplicação central responsável pela gestão e monitoramento de todos os fluxos relacionados à indústria farmacêutica, como receitas, auditoria médica, distribuição, validações, liquidações, relatórios, financiadores, beneficiários, medicamentos, fornecedores e outras funcionalidades. Múltiplas aplicações web em Angular 9+. API REST em NodeJS, express, sequelize, mysql. Ambiente em GCP (Google Cloud Platform).",
  appstractTexto3:
    "• Liderei e participei do desenvolvimento de um aplicativo para o gerenciamento de pacientes, médicos, clientes, materiais, cotações, compras entre outras funcionalidades para uma das maiores drogarias do país, Droguerías del Sud (API Rest in .Net Core com arquitetura baseada em DDD e Web in Angular 9).",
  appstractTexto4:
    "• Participei do desenvolvimento de um painel de controle web (angular 8) e móvel (iônico 4) com um APIREST (NodeJs) e MongoDB, para a gestão e administração interna de documentos de ordem de trabalho da AB InBev Argentina e AB InBev Brasil.",
  appstractTexto5:
    "• Coordenei e participei do desenvolvimento de 2 websites e de um painel de controle, todos dinâmicos e 100% auto-administráveis, para o Grupo Sancor Seguros utilizando MVC .NET + as próprias estruturas da empresa baseadas em VueJs.",

  ldgcodeTitulo: "FUNDADOR",
  ldgcode: "LDGCode",
  ldgcodeFecha: "Ago 2017 - Nov 2020",
  ldgcodeTexto1:
    "Desenvolvimento Web e software personalizado, fornecemos soluções tecnológicas para os problemas que podem surgir nas PMEs ou empresas.",

  gisTitulo: "SOFTWARE DEVELOPER",
  gis: "Get It Smart",
  gisFecha: "Feb 2017 – Ene 2019",
  gisTexto1:
    "• Eu desenvolvi o REST API de uma aplicação web para uma agência de turismo usando o ASP.NET Web API.",
  gisTexto2:
    "• Eu estava em um cliente (Lagash) desenvolvendo a aplicação web para o B20 Argentina (G20 Argentina), utilizando ASP.NET MVC e jQuery",
  gisTexto3:
    "• Participei da manutenção de uma aplicação web para a gestão de cursos e treinamentos do Governo Nacional, utilizando ASP.NET Web API e AngularJS.",
  gisTexto4:
    "• Desenvolvi um REST API de conteúdo estático e uma aplicação web de back office para um sistema de investimento de ativos, usando ASP.NET Core e Angular.",
  gisTexto5:
    "• Coordenei e participei do desenvolvimento de um núcleo REST API, uma API de conteúdo estático e uma aplicação web para um sistema corporativo de catering e gerenciamento de eventos, utilizando ASP.NET Core e Angular.",

  contacto: "Contacto",
  noLoDudes: "Você quer entrar em contato comigo? Não hesite, apenas...",
  dimeHola: "Diga-me Olá",

  curriculum: "CURRICULUM",
  cvText1:
    "Você pode baixar meu CV agora, mas volte logo, você certamente encontrará novas habilidades lá.",
  cvDescargar: "Download",

  cvLink:
  "https://drive.google.com/file/d/1Hie7hNCNDJrdCDSOr2BZrxFPEXvtnEOI/view?usp=sharing",
};
